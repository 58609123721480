import React, {useState} from 'react';
import upd from 'immutability-helper';

import {SelectOption} from '../select-env';
import {Select} from '../components';

interface MultiSelect<T> {
  onChange: (value: T[]) => void;
  options: SelectOption<T>[];
  placeholder?: string;
  style?: React.CSSProperties;
  value: T[];
}

const MultiSelect = <T,>(props: MultiSelect<T>) => {
  const {onChange, options, value} = props;
  const [isActive, setIsActive] = useState(false);

  const handleClick = (optionValue: T) => {
    const index = value.indexOf(optionValue);
    if (index < 0) return onChange([...value, optionValue]);

    onChange(upd(value, {$splice: [[index, 1]]}));
  };

  const labels = options.reduce((acc, option) => {
    if (value.includes(option.value)) acc.push(option.label);
    return acc;
  }, [] as string[]);

  const label = labels.join(', ');

  return (
    <Select
      {...props}
      isActive={isActive}
      label={label}
      onClick={handleClick}
      setIsActive={setIsActive}
    />
  );
};

export default MultiSelect;
