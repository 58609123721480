import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Body,
  Button,
  Icons,
  Nav,
  Page,
  PageBody,
  PageMain,
  Placeholder,
  Preloader
} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Partner from './partner/partner';
import PartnerAdd from './partner-add/partner-add';
import PartnerProgramAdvice from './partner-program-advice/partner-program-advice';

const PartnerProgram = () => {
  const {user} = useWorkspaceContext();

  const [isPartnerAddActive, setIsPartnerAddActive] = useState(false);
  const [partnerList, setPartnerList] = useState<AppEnv.Partner[]>();

  const {fetchPartnersList} = useRequest();
  const {t} = useTranslation();

  const getPartnerList = async () => {
    const {integration} = user;

    const res = await fetchPartnersList(integration.id);
    if (!res) return setPartnerList([]);

    setPartnerList(Object.values(res));
  };

  useEffect(() => {
    document.title = `${t`Partner program`} - Whatcrm Web`;
  }, []);

  useEffect(() => {
    getPartnerList();
  }, []);

  return (
    <>
      <Body>
        <Page>
          <Nav
            options={[{label: t`Partner program`, value: 'partner-program'}]}
            value="partner-program"
          />

          <PageBody>
            <PageMain flexGrow={0}>
              <PartnerProgramAdvice />

              <Button
                color="blue-dash"
                onClick={() => setIsPartnerAddActive(true)}
                width="100%"
              >
                <span>{t`Add client`}</span>
                <Icons.Plus color="blue" size={20} />
              </Button>
            </PageMain>

            {partnerList ? (
              partnerList.length ? (
                partnerList.map(partner => (
                  <Partner
                    key={partner.id}
                    partner={partner}
                    setPartnerList={setPartnerList}
                  />
                ))
              ) : (
                <PageMain>
                  <Placeholder isMaxHeight title={t`No clients`} />
                </PageMain>
              )
            ) : (
              <PageMain>
                <Preloader isMaxHeight />
              </PageMain>
            )}
          </PageBody>
        </Page>
      </Body>

      <PartnerAdd
        isPartnerAddActive={isPartnerAddActive}
        setIsPartnerActive={setIsPartnerAddActive}
        setPartnerList={setPartnerList}
      />
    </>
  );
};

export default PartnerProgram;
