import React, {useRef, useState} from 'react';
import {findPosition, useOnClickOutside, useOnKeyDown} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Icons, Text} from 'components';
import {SelectOption} from '../../select-env';
import {useScrollClassName} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Position {
  left: number;
  top: number;
}

interface Select<T> {
  isActive: boolean;
  isAlt?: boolean;
  isValid?: boolean;
  label: string | undefined;
  onClick: (value: T) => void;
  options: SelectOption<T>[];
  placeholder?: string;
  setIsActive: AppEnv.SetState<boolean>;
  style?: React.CSSProperties;
  value: T | T[];
}

const Select = <T,>({
  isActive,
  isAlt,
  isValid = true,
  label,
  onClick,
  options,
  placeholder,
  setIsActive,
  style,
  value
}: Select<T>) => {
  const [position, setPosition] = useState<Position>();

  const {t} = useTranslation();
  const targetRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(targetRef, {onClick: () => setIsActive(false)});

  useOnKeyDown(
    e => {
      if (e.code == 'Escape') setIsActive(false);
    },
    [isActive]
  );

  const handleActive = () => {
    const res = findPosition(targetRef, {triggerRef});
    const triggerRect = triggerRef.current?.getBoundingClientRect();
    const height = triggerRect?.height || 0;
    const top = res.top + height + (isAlt ? 0 : 8);

    setPosition({...res, top});
    setIsActive(true);
  };

  const scrollClassName = useScrollClassName();
  const triggerRect = triggerRef.current?.getBoundingClientRect();

  return (
    <div
      className={cn('select', {
        select_active: isActive,
        select_alt: isAlt,
        select_multiple: Array.isArray(value)
      })}
      style={style}
    >
      <div
        className={cn('select__field', {select__field_error: !isValid})}
        onClick={handleActive}
        ref={triggerRef}
      >
        <div style={{overflow: 'hidden'}}>
          {placeholder && !(isAlt && label) && (
            <Text
              color={isAlt ? undefined : label ? 'secondary' : 'light'}
              size={label ? 12 : 16}
              fontWeight={isAlt || !label ? undefined : 500}
            >
              {placeholder}
            </Text>
          )}

          {label && (
            <Text fontWeight={isAlt ? undefined : 500} isNoWrap size={16}>
              {label}
            </Text>
          )}
        </div>

        <Icons.MinArrowDown />
      </div>

      <div className="select__dropdown">
        <div
          className={cn('select__dropdown-wrapper', {
            [scrollClassName]: scrollClassName
          })}
          ref={targetRef}
          style={{
            ...position,
            minWidth: (triggerRect?.width || 0) + (isAlt ? 32 : 6)
          }}
        >
          {options.length ? (
            options.map((item, i) => (
              <div
                className={cn('select__option', {
                  select__option_active: Array.isArray(value)
                    ? value.includes(item.value)
                    : value == item.value
                })}
                key={i}
                onClick={() => onClick(item.value)}
              >
                <div
                  className={cn('select__group', {
                    select__group_gap: item.icon
                  })}
                >
                  <Text className="select__label" size={16} isNoWrap>
                    {item.label}
                  </Text>

                  {item.comment && (
                    <div className="select__comment">
                      {item.icon}
                      <Text size={12}>{item.comment}</Text>
                    </div>
                  )}
                </div>

                <Icons.Check size={20} />
              </div>
            ))
          ) : (
            <Text color="light" textAlign="center" size={14}>
              {t`No options`}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default Select;
