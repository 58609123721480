import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Body,
  Nav,
  Page,
  PageBody,
  PageMain,
  Placeholder,
  Preloader,
  Table,
  TableBody,
  TableData,
  TableHead
} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Manager from './manager/manager';
import ManagerCreate from './manager-create/manager-create';
import ManagerSync from './manager-sync/manager-sync';

const Staff = () => {
  const {user} = useWorkspaceContext();
  const [managerList, setManagerList] = useState<AppEnv.Manager[]>();

  const {fetchManagersList} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Staff`} - Whatcrm Web`;
  }, []);

  const getManagerList = async () => {
    const {integration} = user;

    const res = await fetchManagersList(integration.id);
    setManagerList(res || []);
  };

  useEffect(() => {
    getManagerList();
  }, []);

  const {integration} = user;

  return (
    <Body>
      <Page>
        <Nav
          options={[{label: t`Staff`, value: '/staff'}]}
          value={location.pathname}
        />

        <PageBody>
          <PageMain>
            {managerList ? (
              <>
                {integration.type == 'LK' ? (
                  <ManagerCreate setManagerList={setManagerList} />
                ) : (
                  <ManagerSync getManagerList={getManagerList} />
                )}

                {managerList.length ? (
                  <Table>
                    <TableHead>
                      <TableData>{t`Name`}</TableData>
                      <TableData>{t`Email`}</TableData>
                      <TableData>{t`Personal phone number`}</TableData>
                      <TableData>{t`Available numbers`}</TableData>
                      <TableData />
                      <TableData />
                    </TableHead>

                    <TableBody>
                      {managerList.map(manager => (
                        <Manager
                          key={manager.id}
                          manager={manager}
                          setManagerList={setManagerList}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Placeholder isMaxHeight title={t`No staff members`} />
                )}
              </>
            ) : (
              <Preloader isMaxHeight />
            )}
          </PageMain>
        </PageBody>
      </Page>
    </Body>
  );
};

export default Staff;
