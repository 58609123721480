import React from 'react';
import {useTranslation} from 'react-i18next';

import {OrderedList, OrderedListItem, Text} from 'components';
import * as AppEnv from 'app-env';

const useFeatList = (
  crm: AppEnv.Crm,
  tariffPlan: AppEnv.TariffPlan,
  isEnterpriseOption: boolean
) => {
  const {i18n, t} = useTranslation();

  return [
    {
      isChecked: true,
      name: t`Automatic messages with files`,
      skip: crm != 'MONDAY' && crm != 'TGMONDAY'
    },
    {
      isChecked: true,
      name: t`Choosing a pipeline for a phone number`,
      skip: crm !== 'TELEGRAM'
    },
    {
      isChecked: true,
      name: t`CRM plugin for your website`,
      skip: crm == 'MONDAY' || crm == 'TGMONDAY'
    },
    {
      isChecked: true,
      name: `${t`File storage`} ${tariffPlan == 'Enterprise' ? t`12 months` : tariffPlan == 'Pro' ? t`6 months` : t`1 month`}`
    },
    {
      isChecked: true,
      isLimits: true,
      name: t`Sending and receiving files`
    },
    {
      isChecked: true,
      name: t`Unlimited dialogs`
    },
    {
      isChecked: true,
      name: t`Working with chat bots and business Processes`,
      skip: crm !== 'B24' && crm !== 'TELPHGRAM'
    },
    {
      isChecked: true,
      name: t`Working with Salesbot`,
      skip: crm !== 'AMO' && crm !== 'TELEGRAM'
    },
    {
      isChecked: true,
      name: t`Write first`,
      skip: crm == 'TELEGRAM' || crm == 'TELPHGRAM'
    },
    {
      isChecked: crm == 'LK' || ['Enterprise', 'Pro'].includes(tariffPlan),
      name: 'Whatcrm Web'
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Distribution of incoming leads by strategies`,
      skip:
        crm != 'AMO' &&
        crm != 'TELEGRAM' &&
        crm != 'MONDAY' &&
        crm != 'TGMONDAY',
      tooltip: (
        <>
          <Text
            color="white"
            size={14}
            style={{marginBottom: 4}}
          >{t`There are two options:`}</Text>

          <OrderedList>
            <OrderedListItem>{t`New leads are distributed to managers in random order, regardless of how many deals each has.`}</OrderedListItem>
            <OrderedListItem>{t`New leads are distributed evenly to all managers.`}</OrderedListItem>
          </OrderedList>
        </>
      )
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Group chats`
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Missed calls`,
      skip: crm == 'B24' || crm == 'TELPHGRAM'
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Reactions`
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Salesbot with buttons`,
      skip: true
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: 'Whatcrm API',
      skip: crm !== 'LK'
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      name: t`Connecting your own S3 storage`,
      skip: !isEnterpriseOption,
      tooltip: (
        <Text color="white" size={16}>
          {t`Your own S3 storage can be used to store incoming and outgoing files.`}
        </Text>
      )
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      name: t`Private server`,
      skip: !isEnterpriseOption,
      tooltip: (
        <Text color="white" size={16}>
          {t`A server whose resources are only under your control is allocated on request.`}
        </Text>
      )
    },

    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      isShown: true,
      name: t`Sending voice messages in open line chat`,
      skip: crm != 'B24'
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      isShown: true,
      name: 'Поддержка по телефону',
      skip: i18n.resolvedLanguage != 'ru' || !isEnterpriseOption,
      tooltip: (
        <Text color="white" size={16}>
          {'Понедельник–Пятница'}
          <br />
          {'9:00–18:00'}
        </Text>
      )
    }
  ].filter(item => !item.skip);
};

export default useFeatList;
