import React, {useState} from 'react';

import {Select} from '../components';
import {SelectOption} from '../select-env';

interface SingleSelect<T> {
  isAlt?: boolean;
  isValid?: boolean;
  onChange: (arg: T) => void;
  options: SelectOption<T>[];
  placeholder?: string;
  style?: React.CSSProperties;
  value: T;
}

const SingleSelect = <T,>(props: SingleSelect<T>) => {
  const {onChange, options, value} = props;
  const [isActive, setIsActive] = useState(false);

  const handleClick = (optionValue: T) => {
    onChange(optionValue);
    setIsActive(false);
  };

  const {label} = options.find(item => item.value == value) || {};

  return (
    <Select
      {...props}
      isActive={isActive}
      label={label}
      onClick={handleClick}
      setIsActive={setIsActive}
    />
  );
};

export default SingleSelect;
